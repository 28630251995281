<template>
  <div class="work-data-box">
   <!-- <headers></headers> -->
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/item-list' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'job' }"
          >作业管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ name: 'job' }"
          >学员整体数据</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="work-data">
      <div class="charts-box">
        <div class="charts">
          <div class="title-box">
            <div class="title">
              能力图谱
            </div>
          </div>
          <div
            id="myChart-StudentData"
            :style="{ width: '405px', height: '405px', margin: '0 auto' }"
          ></div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              学员表现情况
            </div>
          </div>
          <div class="table-performance-box">
            <el-table
              :data="tableData"
              :header-cell-style="headerCellStyle"
              :cell-style="cellStyle"
            >
              <el-table-column
                prop="secondaryCapability"
                width="180"
                align="center"
                label="二级能力"
              >
              </el-table-column>
              <el-table-column
                prop="bestPerformance"
                width="180"
                align="center"
                label="表现最佳学员"
              >
              </el-table-column>
              <el-table-column
                prop="needPromotion"
                width="180"
                align="center"
                label="亟需提升学员"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="charts-row">
          <div class="title-box">
            <div class="title">
              能力解析
            </div>
          </div>
          <div class="text-box">
            孙老师，您的学员能力报告如下：
            <br />
            <br />
            <img
              src="@/assets/biaoxianyouxiu.png"
              width="24"
              height="24"
              style="vertical-align: text-bottom;"
              alt=""
            /><span class="font-bold">表现优秀：</span>您的学员在<span
              class="important"
              >{{ abilityAnalysis.biaoxianyouxiu }}</span
            >上有着优秀的表现，同时也存在着不足和亟待提升的方面需要您进行关注。
            <br />
            <br />
            <img
              src="@/assets/youdaitisheng.png"
              width="24"
              height="24"
              style="vertical-align: text-bottom;"
              alt=""
            /><span class="font-bold">适当关注：</span>学员们的
            <span class="important"
              >{{ abilityAnalysis.shidangguanzhu }}</span
            >
            有待提升。在教学设计的各环节间缺少适当的层次与过渡，问题情境设计缺乏一定的吸引力，其次在教学活动的设计上，缺少对全班学生参与度的把控。第三，对课程资源不能进行合理筛选，导致课程资源不能有效融入到教学设计与教学实践中。
            <br />
            <br />
            <img
              src="@/assets/jixutisheng.png"
              width="24"
              height="24"
              style="vertical-align: text-bottom;"
              alt=""
            /><span class="font-bold">重点关注：</span
            >学员们亟待提升的能力包括<span class="important"
              >{{ abilityAnalysis.zhongdianguanzhu }}</span
            >。学员们的教学研究理论和方法知识储备不足，缺乏问题意识，缺少对新理论新方法的学习和运用。在教学设计、信息化运用等方面缺乏创新，同时对学生的创新意识的培养与引导也有所缺失。需要您进行重点关注。
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { myChartStudentDataOption } from "@/common/options";
export default {
  data() {
    return {
      abilityAnalysis:{
        biaoxianyouxiu:'课堂调控、学情分析、口语表达能力',
        shidangguanzhu:'教学过程设计能力、课程资源开发与利用能力 ',
        zhongdianguanzhu:'教学策略选择、学习研究与创新能力'
      },
      tableData: [
        {
          secondaryCapability: "学情分析能力",
          bestPerformance: "高丽娜、王静、马小燕",
          needPromotion: "杨玉玉、周悦、王丹妮"
        },
        {
          secondaryCapability: "教学过程设计能力",
          bestPerformance: "史姝迪、高丽娜、马佳",
          needPromotion: "张荣、孙娟、马丽娟"
        },
        {
          secondaryCapability: "教学策略选择能力",
          bestPerformance: "史姝迪、高丽娜、王静",
          needPromotion: "马霞、郭凤、王芳"
        },
        {
          secondaryCapability: "课程资源开发能力",
          bestPerformance: "杨玉玉、姜晓春、郭靖",
          needPromotion: "史姝迪、王鹤、王芳"
        },
        {
          secondaryCapability: "课程资源利用能力",
          bestPerformance: "杨玉玉、姜晓春、郭靖",
          needPromotion: "史姝迪、王鹤、王芳"
        },
        {
          secondaryCapability: "口语表达能力",
          bestPerformance: "张荣、孙娟、马丽娟",
          needPromotion: "马佳、李莉、赵丽娟"
        },
        {
          secondaryCapability: "课堂调控能力",
          bestPerformance: "史姝迪、高丽娜、郭靖",
          needPromotion: "马霞、郭凤、王芳"
        },
        {
          secondaryCapability: "学习研究能力",
          bestPerformance: "杨玉玉、高丽娜、马丽娟",
          needPromotion: "史姝迪、王静、王芳"
        },
        {
          secondaryCapability: "创新能力",
          bestPerformance: "杨玉玉、高丽娜、马丽娟",
          needPromotion: "史姝迪、孙娟、姜晓春"
        }
      ]
    };
  },
  methods: {
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "color:#FFFFFF;background:#2373EC";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 7) {
        return "color:#F33131";
      }
    },
    drawLine() {
      // 学员整体数据-各维度能力平均分
      let myChartStudentData = this.$echarts.init(
        document.getElementById("myChart-StudentData")
      );
      myChartStudentData.setOption(myChartStudentDataOption);
    }
  },
  mounted() {
    this.drawLine();
  }
};
</script>

<style lang="less" scoped>
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .work-data-box {
    background: #fafafa;
    .nav {
      width: 1200px;
      margin: 53px auto 31px;
    }
    .work-data {
      width: 1200px;
      margin: 0 auto 260px;
      background: #ffffff;
    }
    .table-performance-box {
      margin-top: 50px;
      margin-left: 30px;
      padding-bottom: 50px;
    }
  }
}

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .work-data-box {
    background: #fafafa;
    .nav {
      width: 900px;
      margin: 53px auto 31px;
    }
    .work-data {
      width: 900px;
      margin: 0 auto 260px;
      background: #ffffff;
    }
    .table-performance-box {
      width: 540px;
      margin: 50px auto;
      padding-bottom: 50px;
    }
  }
}

.charts-box {
  display: flex;
  flex-wrap: wrap;
  .charts {
    flex: 1;
  }
  .charts-row {
    width: 100%;
  }
  .title-box {
    margin-left: 33px;
    margin-top: 122px;
    .title {
      border-left: 5px solid #2474ec;
      height: 23px;
      padding-left: 12px;
    }
  }
  .text-box {
    width: 85%;
    margin-top: 20px;
    margin-left: 100px;
    margin-bottom: 50px;
    .font-bold {
      font-size: 18px;
      font-weight: bold;
    }
    .important {
      font-size: 18px;
      font-weight: bold;
      color: #2474ec;
    }
  }
}
</style>